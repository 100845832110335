import * as React from "react"
import cx from "classnames";
import {SignUpButton} from "../SignUpButton/SignUpButton";
import {Container, Grid} from "@mui/material";
import * as styles from './FullWidthContent.module.scss';
import BrowserWindow from "../BrowserWindow/BrowserWindow";
import {CanvasMode} from "../3DView/utils";
import Banner3DView from "../3DView/Banner3DView";
import AutoPosition3DView from "../3DView/AutoPosition3DView";
import Rotation3DView from "../3DView/Rotation3DView";
import AutoStocking3DView from "../3DView/AutoStocking3DView";
import Resize3DView from "../3DView/Resize3DView";

interface FullWidthContentProps {
    darkMode: boolean,
    featuredWords?: number[],
    title?: string,
    imgSrc?: string,
    className?: string;
    signInButton?: boolean;
    alightRight?: boolean;
    canvasMode?: CanvasMode;
}

export const FullWidthContent: React.FC<FullWidthContentProps> = (props) => {

    return (
        <div
            className={cx(props.className, styles.FullWidthBanner, {[styles.AlignLeft]: props.alightRight}, {[styles.DarkMode]: props.darkMode})}>
            <Container maxWidth={'lg'}>
                <Grid container flexDirection={props.alightRight ? "row-reverse" : 'row'} spacing={7}>
                    <Grid item xs={12} md={(props.imgSrc || props.canvasMode) ? 4 : 12} className={cx(styles.Content)}>
                        {props.title &&
                        <h3 className={styles.ContentTitle}
                            style={{textAlign: props.canvasMode || props.imgSrc ? 'left' : 'center'}}>
                            {props.title.split(' ')
                                .map((word, index) =>
                                    (props.featuredWords || []).indexOf(index) !== -1 ?
                                        <strong> {word}</strong> : ' ' + word)}
                        </h3>}
                        {props.children}
                        {props.signInButton && <div className={styles.ButtonWrapper}>
                            <SignUpButton className={styles.SignUpBtn}/></div>}
                    </Grid>

                    {props.canvasMode && <Grid item xs={12} md={8} className={cx(styles.ContentImageWrapper)}>
                        <BrowserWindow transparent={props.darkMode}>
                            {props.canvasMode === 'banner' && <Banner3DView/>}
                            {props.canvasMode === 'autoposition' && <AutoPosition3DView/>}
                            {props.canvasMode === 'resize' && <Resize3DView/>}
                            {props.canvasMode === 'rotation' && <Rotation3DView/>}
                            {props.canvasMode === 'autostocking' && <AutoStocking3DView/>}
                        </BrowserWindow>
                    </Grid>}


                    {props.imgSrc && <Grid item xs={12} md={8} className={cx(styles.ContentImageWrapper)}>
                        <BrowserWindow>
                            <img src={props.imgSrc} className={styles.ContentImage} alt={props.title || ""}/>
                        </BrowserWindow>
                    </Grid>}
                </Grid>
            </Container>
        </div>
    )
}

export default FullWidthContent;
