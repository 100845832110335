import * as React from "react"
import Layout from "../components/Layout/Layout";
import FullWidthContent from "../components/FullWidthContent/FullWidthContent";

export default () => (
    <Layout isHomePage={false}>
        <FullWidthContent darkMode={true} title={'Błąd 404'} alightRight={true}
                          featuredWords={[1]}
                          signInButton={false}>

            <p style={{textAlign: 'center'}}>Strona nie odnaleziona</p>

        </FullWidthContent>
    </Layout>
)
