import React, {useEffect, useRef, useState} from "react";
import {
    IPackage,
    trucks, singlePackage,
} from "./utils";
import {useInterval} from "react-use";
import ThreeDView from "./3DView";
import useOnScreen from "../../utils/useOnScreen";

const TRUCK = trucks[2]

interface ViewData {
    packages: IPackage[],
    truckSize: [number, number, number]
}

const HEIGHT = 500;

const Resize3DView = () => {
    const wrapper = useRef<HTMLDivElement>(null);
    const [timer, setTimer] = useState<number>(0);
    const isVisible = useOnScreen(wrapper)

    useInterval(
        () => {
            setTimer(timer + 1);
        },
        isVisible && timer < 100 ? 50 : null
    );

    const getViewData = (): ViewData => {
        const defaultTruckSize = TRUCK.size;
        return {
            packages: [{
                ...singlePackage,
                size: [singlePackage.size[0] + timer, singlePackage.size[1], singlePackage.size[2] + timer * 3]
            }],
            truckSize: [defaultTruckSize[0] + timer, defaultTruckSize[1], defaultTruckSize[2] + timer]
        }
    }
    const {packages, truckSize} = getViewData();
    return (
        <div style={{height: `${HEIGHT}px`}} ref={wrapper}>
            <ThreeDView packages={packages} truckSize={truckSize} />
        </div>
    );
};
export default Resize3DView;
