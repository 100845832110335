import {RefObject, useEffect, useState} from "react";

export const INTERSECTION_OPTIONS = {
    root: null,
    rootMargin: "-80px 0px 0px 0px",
    threshold: 1
}
export default function useOnScreen(ref: RefObject<any>) {

    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting), INTERSECTION_OPTIONS
        )
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect()
        }
    }, [])

    return isIntersecting
}
