import React, {useEffect, useRef, useState} from "react";
import {
    IPackage,
    trucks, singlePackage,
} from "./utils";
import {useInterval} from "react-use";
import ThreeDView from "./3DView";
import useOnScreen from "../../utils/useOnScreen";

const TRUCK = trucks[2]

interface ViewData {
    packages: IPackage[],
    truckSize: [number, number, number]
}

const HEIGHT = 500;

const Rotation3DView = () => {
    const wrapper = useRef<HTMLDivElement>(null);
    const [timer, setTimer] = useState<number>(0);
    const isVisible = useOnScreen(wrapper)
    useInterval(
        () => {
            setTimer(timer + 1);
        },
        isVisible ? 50 : null
    );
    const getViewData = (): ViewData => {
        return {
            packages: [{
                ...singlePackage,
                rotation: (singlePackage.rotation || 0) + timer
            }],
            truckSize: TRUCK.size
        }
    }
    const {packages, truckSize} = getViewData();
    return (
        <div style={{height: `${HEIGHT}px`}} ref={wrapper}>
            <ThreeDView packages={packages} truckSize={truckSize} />
        </div>
    );
};
export default Rotation3DView;
